import React from "react"
import ContactForm from "../components/contract-form"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ContactUs = ({ location }) => (
  <Layout location={location}>
    <SEO title="Contact Us"></SEO>
    <ContactForm></ContactForm>
  </Layout>
)

export default ContactUs
