import React, { useState } from "react"

import { ReCaptcha } from "react-recaptcha-v3"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"

const ContactForm = () => {
  const [firstname, setFirstname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [description, setDescription] = useState("")
  const { register, handleSubmit, errors, reset } = useForm()
  const portalID = process.env.GATSBY_HUBSPOT_PORTAL_ID
  const formID = process.env.GATSBY_HUBSPOT_FORM_ID
  const DEFAULT_SEND_MESSAGE = "Send Message"
  const [reCaptchaToken, setRecaptchaToken] = useState("")
  const [flashMessage, setFlashMessage] = useState("")
  const [flashClass, setFlashClass] = useState("form__result")
  const [sendMessage] = useState(DEFAULT_SEND_MESSAGE)
  const [disabled] = useState(false)

  const submitForm = e => {
    var xhr = new XMLHttpRequest()
    var url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`
    var data = {
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: firstname,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "company",
          value: company,
        },
        {
          name: "description",
          value: description,
        },
      ],
      context: {
        pageUri: "www.mangochango.com",
        pageName: "MangoChango",
      },
    }
    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setFlashMessage("Message sent, we will contact you soon.")
        setFlashClass(" form__result success")
        reset()
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        setFlashMessage("There was an error sending your message.")
        setFlashClass(flashClass + " error")
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        setFlashMessage("There was an error sending your message.")
        setFlashClass(flashClass + " error")
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        setFlashMessage("There was an error sending your message.")
        setFlashClass(flashClass + " error")
      }
    }

    xhr.send(final_data)
  }

  const verifyCallback = token => {
    setRecaptchaToken(token)
  }

  return (
    <div>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_KEY}`}
        ></script>
      </Helmet>
      <ReCaptcha
        action="contact_us"
        sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        verifyCallback={verifyCallback}
      />
      {/* Contact */}
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col col__6">
              <div className="contact__intro">
                <h1 className="title">Let's Talk</h1>
                <p className="description">
                  We are always eager to learn about your business or tell you
                  more about how we can help you.
                </p>
              </div>
              <div className="contact__map">
                <div className="contact__map-pointer" />
                <div className="contact__map-address">
                  <p>
                    4849 Greenville Avenue
                    <br />
                    Suite 100 <br />
                    Dallas, TX 75206
                  </p>
                  <p>
                    <a
                      className="footer__link footer__link--special"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://maps.app.goo.gl/LuuKMF8XFJsAFq6u8"
                    >
                      <span className="icon material-icons">location_on</span>
                      Map &amp; Directions
                    </a>
                  </p>
                  <p>
                    <a
                      className="footer__link footer__link--dark"
                      href="tel:+12147313113"
                    >
                      <span className="icon material-icons">smartphone</span>
                      +1.214.731.3113
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col col__6">
              <div className="contact__form form">
                <form method="post" onSubmit={handleSubmit(submitForm)}>
                  <input
                    type="text"
                    name="g-recaptcha-response"
                    ref={register}
                    style={{ visibility: "hidden" }}
                    defaultValue={reCaptchaToken}
                  />

                  <p className="form__description">
                    Please complete the form below and someone from our team
                    will contact you within 24 hours.
                  </p>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="full-name"
                        type="text"
                        placeholder="Full Name"
                        ref={register({ required: true })}
                        onChange={e => setFirstname(e.target.value)}
                      />

                      {errors["full-name"] && (
                        <p className="form__result error">
                          Full Name field is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="email"
                        placeholder="Email"
                        ref={register({
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        onChange={e => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <p className="form__result error">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="phone"
                        placeholder="Phone"
                        onChange={e => setPhone(e.target.value)}
                        ref={register({ required: true })}
                      />
                      {errors.phone && (
                        <p className="form__result error">Phone is required</p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="company"
                        type="text"
                        placeholder="Company"
                        onChange={e => setCompany(e.target.value)}
                        ref={register({ required: true })}
                      />
                      {errors.company && (
                        <p className="form__result error">
                          Company is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item form__item--textarea">
                      <textarea
                        disabled={disabled ? "disabled" : ""}
                        name="message"
                        placeholder="Your Message"
                        onChange={e => setDescription(e.target.value)}
                        ref={register({ required: true })}
                      />
                      {errors.message && (
                        <p className="form__result error">
                          Message is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row form__row--end">
                    <p className={flashClass}>{flashMessage}</p>
                    <button
                      disabled={disabled ? "disabled" : ""}
                      className="button button--large button--icon-end"
                    >
                      {sendMessage}
                      <span className="icon material-icons">
                        arrow_right_alt
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact */}
    </div>
  )
}
export default ContactForm
